export const BASE_URL = process.env.REACT_APP_BASE_URL + 'api/';
export const IMAGE_BASE_URL = process.env.REACT_APP_BASE_URL + 'storage/';

export const LOGIN = "auth/login";
export const REGISTER = "auth/register";

export const FORGOT_PASSWORD = "auth/forgot-password";
export const RESET_PASSWORD = "auth/reset-password";
export const CHANGE_PASSWORD = "profile/change-password";

export const VERIFY_OTP = "auth/verify";
export const RESEND_OTP = "auth/resend-otp";

export const GET_PROFILE = "profile";
export const UPDATE_PROFILE = "profile/update";

export const MASTER_DATA = "master-data";
export const SYSTEM_DATA = "system-data";

export const GET_CLIENT_LIST = "client";
export const GET_LEAD_LIST = "lead";
export const UPSERT_CLIENT = "client/upsert";
export const UPSERT_LEAD = "lead/upsert";
export const DELETE_CLIENT = "client/delete/";
export const DELETE_LEAD = "lead/delete/";

export const GET_BUSINESS_LIST = "business";
export const UPSERT_BUSINESS = "business/upsert";

export const GET_BANK_LIST = "bank";
export const UPSERT_BANK = "bank/upsert";
export const DELETE_BANK = "bank/delete/";

export const GET_INVOICE_LIST = "invoice";
export const UPSERT_INVOICE = "invoice/upsert";
export const CHANGE_INVOICE_STATUS = "invoice/status/";
export const UPSERT_INVOICE_PAYMENT = "invoice-payment/upsert";
export const DELETE_INVOICE = "invoice/delete/";

export const GET_PROFORMA_LIST = "proforma-invoice";
export const GET_DASHBOARD_DATA = "dashboard/invoice-count";
export const GET_PROFORMA_INVOICE = "proforma-invoice/";
export const UPSERT_PROFORMA = "proforma-invoice/upsert";
export const DELETE_PROFORMA_INVOICE = "proforma-invoice/delete/";



