import moment from 'moment/moment';

export const changeDateFormatForServer = (date) => {
    return date ? moment(date).format("YYYY-MM-DD") : '';
}

export const changeDateFormatForDisplay = (date) => {
    return date ? moment(date).format("DD MMM YYYY") : '';
}

export function tomorrowDate() {
    return moment().add(1, 'days').format("DD/MM/YYYY");
}

export function nowDateTime() {
    return moment().format("YYYY-MM-DD HH:mm:ss");
}

export function dateTime(hours = 0, date = moment()) {
    return moment(date).add(hours, 'hours').format("YYYY-MM-DD HH:mm:ss");
}

export function formatAndAddDate(days, format = "DD-MM-YYYY", date = new Date()) {
    return moment(date, format).add(days, 'days')
}


export function momentToDate(date) {
    return moment(date)?.toDate()
}


export function momentYear() {
    return moment().year()
}


export function isBetween(beginTime, startTime, endTime) {
    return moment(beginTime).isBetween(startTime, endTime) || (moment(beginTime).isSame(moment(startTime)))
}


export function momentResult(input) {
    return moment(input)
}

export function timeFormat(time, format = "h:mm a") {
    return moment(time, format)
}

export function momentDuration(input) {
    return moment.duration(input)
}