import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as storageConstants from '../Constants/storageConstant';
import * as commonServices from '../Services/common';
import { isNullOrEmpty } from '../Utils/stringUtils';

const ProtectedRoute = ({ Component }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isAuthenticated = !isNullOrEmpty(commonServices.getLocalData(storageConstants.AUTH));
    if (!isAuthenticated) {
      navigate("/");
    } else if (isAuthenticated && location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [location.pathname]);

  return (
    <>
      {Component}
    </>
  );
}

export default ProtectedRoute;
