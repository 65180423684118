import './App.css';
import { useState, useEffect, Suspense, lazy, } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { fetchMasterData, getBusinessList, getLocalData, storeLocalData } from './Services/common';
import { AUTH, BUSINESS, BUSINESS_LIST, MASTER_DATA } from './Constants/storageConstant';
import { isNullOrEmpty } from './Utils/stringUtils';
import MainLoader from './Components/_widgets/Loader/MainLoader';
import ProtectedRoute from './Protected/Protected';
import "react-datepicker/dist/react-datepicker.css";
import NotFound from "./Components/NotFound/NotFound"

const Login = lazy(() => import('./Components/Login/Login'));
const LoginLayout = lazy(() => import('./Components/Layouts/LoginLayout'));
const ResetPassword = lazy(() => import('./Components/ResetPassword/ResetPassword'));
const HomeLayout = lazy(() => import('./Components/Layouts/HomeLayout'));
const Dashboard = lazy(() => import('./Components/Dashboard/Dashboard'));
const BusinessSettings = lazy(() => import('./Components/BusinessSettings/BusinessSettings'));
const ChangePassword = lazy(() => import('./Components/ChangePassword/ChangePassword'));
const Clients = lazy(() => import('./Components/Clients/Clients'));
const Invoices = lazy(() => import('./Components/Invoices/Invoices'));
const InvoicePage = lazy(() => import('./Components/Invoices/InvoicePage'));
const Register = lazy(() => import('./Components/Register/Register'));
const Profile = lazy(() => import('./Components/Profile/Profile'));
const ProformaInvoice = lazy(() => import('./Components/ProformaInvoice/ProformaInvoice'));
const BankDetails = lazy(() => import('./Components/BankDetails/BankDetails'));
const Lead = lazy(() => import('./Components/Lead/Lead'));

function App() {
  const location = useLocation();
  const auth = getLocalData(AUTH);

  const [mainLoading, setMainLoading] = useState(false);
  const [businessList, setBusinessList] = useState([]);
  const [business, setBusiness] = useState(getLocalData(BUSINESS));
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const data = getLocalData(MASTER_DATA);

    if (isNullOrEmpty(data)) {
      fetchMasterData(setMainLoading);
    }
  }, []);

  useEffect(() => {
    storeLocalData(BUSINESS, business);
  }, [business?.id]);

  useEffect(() => {
    const list = getLocalData(BUSINESS_LIST);
    if (auth && (isNullOrEmpty(list) || list?.length < 1)) {
      const isDataFetched = getBusinessList(`?limit=10000`, setBusinessList);
      if (isDataFetched) {
        setBusinessList(isDataFetched);
        setDataFetched(prev => !prev);
      };
    } else if (auth) {
      setBusinessList(list);
    }
  }, []);

  useEffect(() => {
    if (auth) {
      const list = getLocalData(BUSINESS_LIST);
      if (list?.length > 0) {
        const businessObj = getLocalData(BUSINESS);
        if (isNullOrEmpty(businessObj?.id)) {
          const newObj = list[0];
          setBusinessList(list);
          storeLocalData(BUSINESS, newObj);
          setBusiness(newObj);
        }
      }
    }
  }, [dataFetched]);

  return (
    <div className="App">
      <ToastContainer />
      <Suspense fallback={<MainLoader />}>
        {mainLoading
          ? <MainLoader />
          : <Routes>
            <Route element={<LoginLayout />} >
              <Route exact path='/' element={<ProtectedRoute Component={<Login location={location} setBusiness={setBusiness} setBusinessList={setBusinessList} />} />} />
              <Route exact path='/register' element={<Register location={location} />} />
              <Route exact path='/reset-password' element={<ResetPassword location={location} />} />
              <Route exact path="/*" element={<NotFound />} />
            </Route>

            <Route element={<HomeLayout businessList={businessList} business={business} setBusiness={setBusiness} setBusinessList={setBusinessList} />}>
              <Route exact path="/profile" element={<ProtectedRoute Component={<Profile />} />} />
              <Route exact path="/change-password" element={<ProtectedRoute Component={<ChangePassword />} />} />
              <Route exact path="/dashboard" element={<ProtectedRoute Component={<Dashboard business={business} />} />} />
              <Route exact path="/clients" element={<ProtectedRoute Component={<Clients business={business} />} />} />
              <Route exact path="/leads" element={<ProtectedRoute Component={<Lead business={business} />} />} />
              <Route exact path="/invoices" element={<ProtectedRoute Component={<Invoices business={business} />} />} />
              <Route exact path="/invoice-page" element={<ProtectedRoute Component={<InvoicePage business={business} />} />} />
              <Route exact path="/pro-invoices" element={<ProtectedRoute Component={<ProformaInvoice business={business} />} />} />
              <Route exact path="/business-settings" element={<ProtectedRoute Component={<BusinessSettings business={business} setBusiness={setBusiness} setDataFetched={setDataFetched} setBusinessList={setBusinessList} />} />} />
              <Route exact path="/bank-details" element={<ProtectedRoute Component={<BankDetails business={business} />} />} />
            </Route>
          </Routes>}
      </Suspense>
    </div>
  );
}

export default App;
