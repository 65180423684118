import * as apiProvider from './provider';
import * as urlConstants from '../Constants/urlConstants';

export function login(reqData) {
  return apiProvider.post(urlConstants.LOGIN, reqData);
}

export function register(reqData) {
  return apiProvider.post(urlConstants.REGISTER, reqData);
}

export function resetPassword(reqData) {
  return apiProvider.post(urlConstants.RESET_PASSWORD, reqData)
}

export function changePassword(reqData) {
  return apiProvider.post(urlConstants.CHANGE_PASSWORD, reqData)
}

export function forgotPassword(reqData) {
  return apiProvider.post(urlConstants.FORGOT_PASSWORD, reqData)
}

export function verifyOtp(reqData) {
  return apiProvider.post(urlConstants.VERIFY_OTP, reqData)
}

export function resendOtp(reqData) {
  return apiProvider.post(urlConstants.RESEND_OTP, reqData)
}

export function getProfile() {
  return apiProvider.getAll(urlConstants.GET_PROFILE)
}

export function updateProfile(reqData) {
  return apiProvider.post(urlConstants.UPDATE_PROFILE, reqData)
}

export function masterData() {
  return apiProvider.getAll(urlConstants.MASTER_DATA);
}

export function systemData() {
  return apiProvider.getAll(urlConstants.SYSTEM_DATA);
}

export async function getClientList(query, reqData) {
  return await apiProvider.post(urlConstants.GET_CLIENT_LIST + query, reqData);
}

export async function getLeadList(query, reqData) {
  return await apiProvider.post(urlConstants.GET_LEAD_LIST + query, reqData);
}

export function upsertClient(reqData) {
  return apiProvider.post(urlConstants.UPSERT_CLIENT, reqData);
}

export function upsertLead(reqData) {
  return apiProvider.post(urlConstants.UPSERT_LEAD, reqData);
}

export function deleteLead(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_LEAD + id);
}

export function deleteClient(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_CLIENT + id);
}

export function getBusinessList(query) {
  return apiProvider.post(urlConstants.GET_BUSINESS_LIST + query);
}

export function getBusinessDetails(id) {
  return apiProvider.get(urlConstants.GET_BUSINESS_LIST + '/', id);
}

export function upsertBusiness(reqData) {
  return apiProvider.post(urlConstants.UPSERT_BUSINESS, reqData);
}

export function getBankList(query, reqData) {
  return apiProvider.post(urlConstants.GET_BANK_LIST + query, reqData);
}

export function upsertBank(reqData) {
  return apiProvider.post(urlConstants.UPSERT_BANK, reqData);
}

export function deleteBank(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_BANK + id);
}

export function getInvoiceList(query, reqData) {
  return apiProvider.post(urlConstants.GET_INVOICE_LIST + query, reqData);
}

export function getInvoiceById(id) {
  return apiProvider.get(urlConstants.GET_INVOICE_LIST + '/', id);
}

export function upsertInvoice(reqData) {
  return apiProvider.post(urlConstants.UPSERT_INVOICE, reqData);
}

export function changeInvoiceStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_INVOICE_STATUS + id, reqData);
}

export function upsertInvoicePayment(reqData) {
  return apiProvider.post(urlConstants.UPSERT_INVOICE_PAYMENT, reqData);
}

export function deleteInvoice(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_INVOICE + id);
}

export function getProformaList(query, reqData) {
  return apiProvider.post(urlConstants.GET_PROFORMA_LIST + query, reqData);
}

export function getProformaInvoice(id) {
  return apiProvider.get(urlConstants.GET_PROFORMA_INVOICE, id);
}

export function upsertProforma(reqData) {
  return apiProvider.post(urlConstants.UPSERT_PROFORMA, reqData);
}

export function deleteProformaInvoice(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_PROFORMA_INVOICE + id);
}
export function getDashboardData(reqData) {
  return apiProvider.post(urlConstants.GET_DASHBOARD_DATA, reqData);
}